import { Pipe, PipeTransform } from '@angular/core';
import { StatusToColorClassMap } from '@shared/constants';

@Pipe({
  name: 'statusColor',
  standalone: false,
})
export class StatusColorPipe implements PipeTransform {
  transform(value: any): string {
    return StatusToColorClassMap.get(value?.toLowerCase()) || '';
  }
}
