import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@core/injectors';

@Injectable()
export class ThemeService {
  private readonly THEME_KEY = 'dart-theme';
  private readonly DARK_THEME_NAME = 'theme-dark';

  get isDarkTheme(): boolean {
    return parseInt(this.storage.getItem(this.THEME_KEY) || '0') !== 0;
  }

  constructor(
    @Inject(DOCUMENT) private readonly doc: Document,
    @Inject(LOCAL_STORAGE) private readonly storage: Storage,
    readonly renderFactory: RendererFactory2,
  ) {
    this.setDarkTheme(this.isDarkTheme);
  }

  private setDarkTheme(isDarkTheme: boolean) {
    const root = this.doc.querySelector(':root');
    if (isDarkTheme) {
      root?.classList.add(this.DARK_THEME_NAME);
    } else {
      root?.classList.remove(this.DARK_THEME_NAME);
    }
  }

  toggle() {
    this.storage.setItem(this.THEME_KEY, (this.isDarkTheme ? 0 : 1) + '');
    this.setDarkTheme(this.isDarkTheme);
  }
}
