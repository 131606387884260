<header>
  <mat-toolbar>
    <a class="navbar-brand" [routerLink]="homeUrl()">
      <img src="assets/main_logo_sm.png" alt="Diliko Portal" loading="lazy" class="brand-logo" />
    </a>
    @if (userInfo(); as userInfo) {
      <div class="tools-panel">
        <app-tools-panel [userInfo]="userInfo"></app-tools-panel>
      </div>
      <button class="user-profile-panel" [matMenuTriggerFor]="menu">
        <!--
            <div class="user-picture"></div>
          -->
        <div class="user-info">
          <div class="user-name text-ellipsis">
            {{ userInfo.name }}
          </div>
          <div class="user-organization text-ellipsis">
            {{ userOrganization() }}
          </div>
        </div>
      </button>

      <mat-menu #menu="matMenu" classList="menu-shadow">
        <!--    <button mat-menu-item [routerLink]="['user']">Profile</button>-->
        <button mat-menu-item [routerLink]="['/auth', 'logout']">Log Out</button>
      </mat-menu>
    }
  </mat-toolbar>
</header>
