import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  protected userPermissions: string[] = [];
  protected allowEmptyUserPermission = true;

  getPermissionsList(): Observable<string[]> {
    return of([]);
  }

  decodePermissionsList(permissions: string[]): boolean {
    this.userPermissions = permissions;
    return true;
  }

  hasAccess(permissions: string[]): boolean {
    if (Array.isArray(permissions) && permissions.length) {
      return permissions.some((permission) => this.userPermissions.includes(permission));
    }
    return false;
  }

  hasAccessAsync(permissions: string[]): Observable<boolean> {
    return of(this.hasAccess(permissions));
  }
}
