import { IEnvironment } from './environment.model';

export const environment: IEnvironment = {
  production: false,
  productName: 'Diliko.ai',
  apiUrl: 'https://portal.qa.diliko.us',
  zendeskUrl: 'https://dilikoqa.zendesk.com',
  postLogoutUri: 'https://diliko.ai/',
  zendeskLogout: false,
  sentryEnable: true,
  sentryDNS: 'https://0ce1e39c3d75e94a537a91063a6fdc74@o4506751417188353.ingest.us.sentry.io/4506751431475200',
  sentryENV: 'QA',
  idleTime: 28 * 60 * 1000,
  timeoutTime: 2 * 60,
  restoreTokenTime: 10 * 60 * 1000,
};
