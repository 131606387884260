const cache = new Map<string, string>();

export function toCamelCase(value: string, space: string = ' '): string {
  if (!value) {
    return '';
  }

  const key = `${value}-${space}`;
  if (cache.has(key)) {
    return cache.get(key)!;
  } else {
    const res = value
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toUpperCase() : word.toLowerCase();
      })
      .replace(/\s+/g, space);

    cache.set(key, res);

    return res;
  }
}
