import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[stopPropagationScroll]',
  standalone: false,
})
export class StopPropagationScrollDirective {
  constructor(private el: ElementRef) {}
  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    const scrollableContainer = this.el.nativeElement;
    if (scrollableContainer.scrollHeight > scrollableContainer.clientHeight) {
      event.preventDefault();
      scrollableContainer.scrollTop += event.deltaY;
    }
  }
}
