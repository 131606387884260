import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class ServerErrorHandlerService {
  /**
   * This function takes an HttpErrorResponse with a status of 400 Bad Request, and
   * a FormGroup. It iterates over the error object's keys, and for each one, it
   * finds the corresponding control in the form and sets the error to the
   * error message(s) for that key. The error messages are nested under the
   * 'server' key in the errors object.
   *
   * The third parameter is an optional function that takes the property name as
   * an argument and returns the name of the form field. This is useful if the
   * server error property names don't match the form control names.
   *
   * @returns {boolean} True if the error was processed, false otherwise.
   */
  formValidationError(
    error: HttpErrorResponse,
    form: FormGroup,
    propertyNameTransform: (propertyName: string) => string = (propertyName: string) => propertyName,
  ): boolean {
    if (error.status === HttpStatusCode.BadRequest) {
      Object.keys(error.error).forEach((propertyName: string) => {
        const formFieldName: string = propertyNameTransform(propertyName);
        form.get(formFieldName)?.setErrors({
          server: Array.isArray(error.error[propertyName]) ? error.error[propertyName] : [error.error[propertyName]],
        });
      });

      return true;
    }

    return false;
  }

  formControlValidationError(error: HttpErrorResponse, formControl: FormControl): boolean {
    if (error.status === HttpStatusCode.BadRequest) {
      Object.keys(error.error).forEach((propertyName: string) => {
        formControl?.setErrors({
          server: Array.isArray(error.error[propertyName]) ? error.error[propertyName] : [error.error[propertyName]],
        });
      });

      return true;
    }

    return false;
  }
}
