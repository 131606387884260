import { Pipe, PipeTransform } from '@angular/core';
import {
  ApprovalStatusStringMap,
  PipelineReadinessStringMap,
  PipelineStatusStringMap,
  VpnTypeStringMap,
} from '@shared/constants';

@Pipe({
  name: 'serverStringConverter',
  standalone: false,
})
export class ServerStringConverterPipe implements PipeTransform {
  transform(value: string, type: string): string {
    let convertedString = '';
    switch (type) {
      case 'vpnType':
        convertedString = VpnTypeStringMap.get(value) || value;
        break;
      case 'approvalStatus':
        convertedString = ApprovalStatusStringMap.get(value) || value;
        break;
      case 'pipelineStatus':
        convertedString = PipelineStatusStringMap.get(value) || value;
        break;
      case 'pipelineReadiness':
        convertedString = PipelineReadinessStringMap.get(value) || value;
        break;
      default:
        convertedString = value;
    }
    return convertedString;
  }
}
