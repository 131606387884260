import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '@core/injectors';
import { SsoTokenObtainResponse } from '@core/models';
import { AccountService } from '@features/server-side-authentication';
import { Observable, catchError, finalize, map, of } from 'rxjs';
import { SsoLoginDto } from '../../models';

@Injectable()
export class AuthenticationService {
  constructor(
    private readonly http: HttpClient,
    @Inject(API_BASE_URL) private readonly apyBaseUrl: string,
    private readonly accountService: AccountService,
  ) {}

  public login(email: string): Observable<SsoLoginDto> {
    return this.http.get<SsoLoginDto>(`${this.apyBaseUrl}/api/auth/login/?email=${email}`);
  }

  public restoreToken(): Observable<boolean> {
    return this.http
      .post<SsoTokenObtainResponse>(`${this.apyBaseUrl}/api/auth/token/refresh/`, {
        refresh: this.accountService.refreshToken,
      })
      .pipe(
        map((response) => {
          this.accountService.accessToken = response.access;
          return true;
        }),
        catchError(() => of(false)),
      );
  }

  public ssoLoginConfirmation(code: string): Observable<boolean> {
    return this.http.get<SsoTokenObtainResponse>(`${this.apyBaseUrl}/api/auth/token/obtain/?code=${code}`).pipe(
      map((response) => {
        this.accountService.accessToken = response.access;
        this.accountService.refreshToken = response.refresh;
        return true;
      }),
    );
  }

  // Clear token if it present in a storage
  public reset() {
    this.accountService.clear();
  }

  public logout() {
    return this.http.get(`${this.apyBaseUrl}/api/auth/logout/`).pipe(
      finalize(() => {
        this.reset();
      }),
      catchError(() => of(true)),
    );
  }
}
