import { FormGroup } from '@angular/forms';

/**
 * Returns an object with only the changed values from the form.
 * @param form - The form to get the changed values from.
 * @returns An object with the changed values.
 */
function getFormChangedValues(form: FormGroup) {
  const changedValues: any = {};
  Object.keys(form.controls).forEach((key) => {
    const control = form.get(key);
    // If the control is dirty, it means the value has changed
    if (control?.dirty) {
      changedValues[key] = control.value;
    }
  });
  return changedValues;
}

export const getFormChangedValue = getFormChangedValues;
