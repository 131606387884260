import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../services/account/account.service';

function findOrganizationRoot(route: ActivatedRoute | null) {
  if (!route) {
    return null;
  }

  if ((route.component as any)?.isOrganizationRootPage) {
    return route;
  } else {
    return findOrganizationRoot(route.firstChild);
  }
}

export const internalUserInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const account = inject(AccountService);
  const activatedRoute = inject(ActivatedRoute);
  const isLoggedIn = !!account.accessToken;

  if (isLoggedIn) {
    let params = req.params;
    if (account.isInternal) {
      const orgRoute = findOrganizationRoot(activatedRoute);

      if (orgRoute) {
        params = params.set('as_customer', orgRoute.snapshot.params['organizationId']);
      }
    }

    req = req.clone({
      params,
    });
  }

  return next(req);
};
