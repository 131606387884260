import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AccountService } from '@features/server-side-authentication';
import { catchError, map, of, tap } from 'rxjs';
import { UserService } from '../services/user/user.service';

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  const userService = inject(UserService);
  const accountService = inject(AccountService);

  return userService.loadUserData().pipe(
    tap((userData) => userService.setUserData(userData)), // userData is the emitted value
    map(() => true),
    catchError((err: HttpErrorResponse) => {
      switch (err.status) {
        case 0:
          return of(router.createUrlTree(['/unknown-error']));
        case HttpStatusCode.Unauthorized:
          accountService.clear();
          return of(router.createUrlTree(['/auth/login']));
        default:
          return of(router.createUrlTree(['/unknown-error'])); //'/un-authenticated'
      }
    }),
  );
};
