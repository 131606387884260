import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[stopPropagationAction]',
  standalone: false,
})
export class StopPropagationActionDirective {
  @HostListener('click', ['$event'])
  handleClick(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();
  }
}
