import { Component } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-page-unknown-error',
  templateUrl: './page-unknown-error.component.html',
  styleUrl: './page-unknown-error.component.scss',
  standalone: false,
})
export class PageUnknownErrorComponent {
  main_site_url = environment.postLogoutUri;
}
