import { Directive, ViewContainerRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[appIsOverflowField]',
  standalone: false,
})
export class IsOverflowFieldDirective {
  constructor(private vievRef: ViewContainerRef) {}

  ngAfterViewInit() {
    const element = this.vievRef.element.nativeElement;

    const curOverflow = element.style.overflow;
    if (!curOverflow || curOverflow === 'visible') element.style.overflow = 'hidden';
    const isOverflowing = element.clientWidth < element.scrollWidth || element.clientHeight < element.scrollHeight;
    element.style.overflow = curOverflow;

    this.vievRef.injector.get(MatTooltip).disabled = !isOverflowing;
  }
}
