import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { accessPageGuard } from '@features/security';
import { HomeComponent } from '../../layout/components';
import { HomeOrganizationComponent } from './components/home-organization/home-organization.component';
import { PageOrganizationComponent } from './components/page-organization/page-organization.component';

const routes: Routes = [
  {
    path: '',
    component: HomeOrganizationComponent,
    pathMatch: 'full',
  },
  {
    path: ':organizationId',
    component: PageOrganizationComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
        pathMatch: 'full',
      },
      {
        path: 'data-sources',
        loadChildren: () => import('@features/data-sources/data-sources.module').then((mod) => mod.DataSourcesModule),
      },
      {
        path: 'user-management',
        loadChildren: () => import('@features/user-management').then((mod) => mod.UserManagementModule),
        canActivate: [accessPageGuard],
        data: {
          permissions: ['view_user'],
        },
      },
      {
        path: 'role-management',
        loadChildren: () => import('@features/role-management').then((mod) => mod.RoleManagementModule),
        canActivate: [accessPageGuard],
        data: {
          permissions: ['view_role'],
        },
      },
      {
        path: 'categories',
        loadChildren: () => import('@features/categories').then((mod) => mod.CategoriesModule),
        canActivate: [accessPageGuard],
        data: {
          permissions: ['view_category'],
        },
      },
      {
        path: 'pipelines',
        loadChildren: () => import('@features/pipelines').then((mod) => mod.PipelinesModule),
        canActivate: [accessPageGuard],
        data: {
          permissions: ['view_pipeline'],
        },
      },
      {
        path: 'connectors',
        loadChildren: () => import('@features/connectors').then((mod) => mod.ConnectorsModule),
        canActivate: [accessPageGuard],
        data: {
          permissions: ['view_connector'],
        },
      },
      {
        path: 'audit-log',
        loadChildren: () => import('@features/audit-log').then((mod) => mod.AuditLogModule),
        canActivate: [accessPageGuard],
        data: {
          permissions: ['view_auditlog'],
        },
      },
      {
        path: 'notifications',
        loadChildren: () => import('@features/notification-management').then((mod) => mod.NotificationManagementModule),
        canActivate: [accessPageGuard],
        data: {
          permissions: ['view_notifications'],
        },
      },
      {
        path: 'vpn-configuration',
        loadChildren: () => import('@features/vpn-configuration').then((mod) => mod.VpnConfigurationModule),
        canActivate: [accessPageGuard],
        data: {
          permissions: ['view_vpnconfig'],
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrganizationsRoutingModule {}
