import { Component, Inject, input, OnInit, signal } from '@angular/core';
import { WINDOW } from '@core/injectors';
import { UserInfo } from '@core/models';
import { ThemeService } from '@core/services';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-tools-panel',
  templateUrl: './tools-panel.component.html',
  styleUrl: './tools-panel.component.scss',
  standalone: false,
})
export class ToolsPanelComponent implements OnInit {
  userInfo = input.required<UserInfo>();
  isDarkTheme = signal<boolean>(false);

  constructor(
    private readonly themeService: ThemeService,
    @Inject(WINDOW) private readonly win: Window,
  ) {}

  ngOnInit(): void {
    this.isDarkTheme.set(this.themeService.isDarkTheme);
  }

  onToggle() {
    this.themeService.toggle();
    this.isDarkTheme.set(this.themeService.isDarkTheme);
  }

  navigateToZendesk() {
    this.win.open(
      environment.zendeskUrl + (this.userInfo()?.zendesk_agent ? '/agent/' : '/hc/en-us/requests/organization'),
    );
  }

  navigateToSnowflake() {
    this.win.open(this.userInfo()?.snowflake_url);
  }

  navigateToThoughspot() {
    this.win.open(this.userInfo()?.thoughspot_url);
  }
}
