import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm-close',
  templateUrl: './dialog-confirm-close.component.html',
  styleUrl: './dialog-confirm-close.component.scss',
  standalone: false,
})
export class DialogConfirmCloseComponent {
  constructor(public dialogRef: MatDialogRef<DialogConfirmCloseComponent>) {}
}
