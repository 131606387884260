import { Component, input } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrl: './info-card.component.scss',
  standalone: false,
})
export class InfoCardComponent {
  title = input.required<string>();
}
