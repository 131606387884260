import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  input,
  signal,
} from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface FormErrorParams {
  max?: number;
  min?: number;
  requiredLength?: number;
  minValue?: number;
  maxValue?: number;
  exactLength?: number;
  example?: string;
  server?: string;
}

interface FormErrorMessage {
  [key: string]: { label: string; params: (error: FormErrorParams) => any[] };
}

const errorMessages: FormErrorMessage = {
  server: { label: '$1', params: (error: any) => [error.join(' '), null] },
  required: { label: 'Required', params: () => [null, null] },
  email: { label: 'Please enter a work email address', params: () => [null, null] },
  max: { label: 'Maximum value is $1', params: (error: FormErrorParams) => [error.max, null] },
  min: { label: 'Minimum value is $1', params: (error: FormErrorParams) => [error.min, null] },
  maxlength: {
    label: 'Must be up to $1 characters long',
    params: (error: FormErrorParams) => [error.requiredLength, null],
  },
  minlength: {
    label: 'Must be at least $1 characters long',
    params: (error: FormErrorParams) => [error.requiredLength, null],
  },
  tworequired: { label: 'Both fields are required', params: () => [null, null] },
  ip: { label: 'Please enter a valid IP address', params: () => [null, null] },
  integer: { label: 'Please enter a valid integer number', params: () => [null, null] },
  ipd: { label: 'Please enter a valid data', params: () => [null, null] },
  secret: { label: 'Please enter a valid secret', params: () => [null, null] },
  warehouse_name: {
    label: 'Warehouse column name can only contain letters, numbers, or underscores',
    params: () => [null, null],
  },
  user_name: {
    label: 'User name can only contain letters',
    params: () => [null, null],
  },
  empty_input: { label: 'No data loaded', params: () => [null, null] },
  //noValues: { label: 'Not available', params: () => [null, null] },
  //whitespace: { label: 'Invalid entry', params: () => [null, null] },
};

@Component({
  selector: 'field-errors-messages',
  templateUrl: './field-errors-messages.component.html',
  styleUrl: './field-errors-messages.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FieldErrorsMessagesComponent implements OnChanges, OnDestroy {
  private readonly INVALID_STATUS = 'INVALID';

  control = input<FormControl | AbstractControl | null>(null);
  empty = input<any | null | undefined, any>(undefined, {
    transform: (value) => {
      if (value === null) {
        const control = this.control();

        if (control) {
          control.markAsTouched();
          control.setValue(null);
          (control.parent || control)?.updateValueAndValidity();
        }
        this.showErrorEmptyData();
      }
      return value;
    },
  });
  errorText = signal<string | null>(null);

  private subs!: Subscription;

  constructor(private readonly ref: ChangeDetectorRef) {}

  ngOnChanges(ev: SimpleChanges) {
    if (ev['control']?.currentValue) {
      this.setErrors();
    }
  }

  ngOnDestroy() {
    this.subs?.unsubscribe();
  }

  setErrors() {
    const control = this.control();
    if (control) {
      if (control.status === this.INVALID_STATUS) {
        this.showError(control);
      }
      this.subs?.unsubscribe();
      this.subs = control.statusChanges.subscribe((status) => {
        if (status === this.INVALID_STATUS && control?.errors) {
          this.showError(control);
        } else if (this.empty() !== null) {
          this.removeError();
        }
      });
    }
  }

  private showError(control: FormControl | AbstractControl) {
    this.errorText.set(this.setValidationMsg(control.errors));
    this.ref.markForCheck();
  }

  private showErrorEmptyData() {
    this.errorText.set(this.setValidationMsg({ empty_input: '' }));
    this.ref.markForCheck();
    this.ref.detectChanges();
  }

  private removeError(): void {
    this.errorText.set(null);
    this.ref.markForCheck();
  }

  setValidationMsg(valErrors: ValidationErrors | null): string | null {
    let errorMsg: string | null = null;
    if (valErrors) {
      const errorKey = Object.keys(valErrors)[0];
      const errorValue = valErrors[errorKey];
      const errorMessageItem = errorMessages[errorKey];

      if (errorMessageItem) {
        const [errorParam1, errorParam2] = errorMessageItem.params(errorValue);

        errorMsg = errorMessageItem.label;
        if (errorParam1 !== null) {
          errorMsg = errorMsg.replace('$1', errorParam1);
        }
        // For to parameters error
        //if (errorParam2 !== null) {
        //  errorMsg = errorMsg.replace('$2', errorParam2);
        //}
      }
    }
    return errorMsg;
  }
}
