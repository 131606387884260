import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat',
  standalone: false,
})
export class DateFormatPipe implements PipeTransform {
  constructor(private readonly datePipe: DatePipe) {}

  transform(value: string | null | undefined): any {
    if (!value) {
      return '';
    }
    const date = new Date(value.replace(/\s+/g, ''));
    if (isNaN(date.getTime())) {
      return '';
    }
    return this.datePipe.transform(date, 'd MMMM y h:mm a', 'UTC');
  }
}
