import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn } from '@angular/router';
import { LOCAL_STORAGE } from '@core/injectors';

export const deactivateUserPageGuard: CanDeactivateFn<any> = (component: any, currentRoute: ActivatedRouteSnapshot) => {
  const storage = inject(LOCAL_STORAGE);
  const url = (currentRoute as any)._routerState.url;
  url && storage.setItem('redirect_url', url);
  return true;
};
