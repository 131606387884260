import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccountService } from './services/account/account.service';
import { AuthenticationService } from './services/authentication/authentication.service';

@NgModule({
  declarations: [],
  providers: [AccountService, AuthenticationService],
  imports: [CommonModule, RouterModule],
})
export class ServerSideAuthenticationModule {}
