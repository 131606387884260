import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@core/injectors';
import { environment } from '@environments/environment';
import { JwtTokenData } from '../../models';

@Injectable()
export class AccountService {
  private readonly accessStoreKey = 'ac';
  private readonly refreshStoreKey = 're';

  private _accessToken!: string;
  private _refreshToken!: string;
  private _decodedToken!: JwtTokenData | null;

  get accessToken(): string {
    return this._accessToken;
  }

  set accessToken(value: string) {
    this.storage.setItem(this.accessStoreKey, value);
    this._decodedToken = this.jwtTokenDecode(value);
    this._accessToken = value;
  }

  get refreshToken(): string {
    return this._refreshToken;
  }

  set refreshToken(value: string) {
    this.storage.setItem(this.refreshStoreKey, value);
    this._refreshToken = value;
  }

  get isUserMimic(): boolean {
    return !!this._decodedToken?.mimicing_user_id;
  }

  get isInternal(): boolean {
    return this._decodedToken?.internal || false;
  }

  get userId(): number | undefined {
    return this._decodedToken?.user_id;
  }

  constructor(
    @Inject(WINDOW) private readonly window: Window,
    @Inject(LOCAL_STORAGE) private readonly storage: Storage,
  ) {
    this._accessToken = this.storage.getItem(this.accessStoreKey) || '';
    this._decodedToken = this.jwtTokenDecode(this._accessToken);
    this._refreshToken = this.storage.getItem(this.refreshStoreKey) || '';

    if (environment.staticToken) {
      this._accessToken = environment.staticToken;
      this._decodedToken = {} as any;
    }
  }

  public clear(): void {
    this._accessToken = '';
    this._refreshToken = '';
    this._decodedToken = null;
    this.storage.removeItem(this.accessStoreKey);
    this.storage.removeItem(this.refreshStoreKey);
  }

  private jwtTokenDecode(token: string): JwtTokenData | null {
    if (!token) {
      return null;
    }
    const parts = token.split('.');
    if (parts.length !== 3) {
      return null;
    }

    const base64Url = parts[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      this.window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }
}
