import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@core/injectors';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private readonly console: Console = this.win.console;

  constructor(@Inject(WINDOW) private readonly win: any) {}

  log(message?: any, ...optionalParams: any[]) {
    this.console.log(message, ...optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]) {
    this.console.warn(message, ...optionalParams);
  }

  error(message?: any, ...optionalParams: any[]) {
    this.console.error(message, ...optionalParams);
  }
}
