<mat-card class="col statistics-tile" [attr.data-test-id]="statistic()?.name">
  <mat-card-header>
    <h2 class="title">
      {{ statistic()?.name }}
    </h2>
  </mat-card-header>
  <mat-card-content class="d-grid align-items-center ustify-content-center statistic">
    <div class="amount">{{ statistic()?.amount }}</div>
    @if (statistic()?.fullyReady || statistic()?.fullyReady === 0) {
      <div class="fully-ready">{{ statistic()?.fullyReady }} fully ready</div>
    }
  </mat-card-content>
</mat-card>
