<mat-card data-test-id="Open Issues">
  <mat-card-header>
    <h2>Open Issues</h2>
  </mat-card-header>
  <mat-card-content>
    <div class="table-content">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef class="header-border-right">#</th>
          <td mat-cell *matCellDef="let element" class="table-elem-border-right">{{ element.number }}</td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>Title</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.url; else plainTitle">
              <a
                class="table-link"
                [href]="
                  zendeskAgent
                    ? environment.zendeskUrl + '/agent/tickets/' + element.number
                    : environment.zendeskUrl + '/hc/en-us/requests/' + element.number
                "
              >
                {{ element.title }}
              </a>
            </ng-container>
            <ng-template #plainTitle>
              {{ element.title }}
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-set aria-label="status" class="status-chip chip-size-m" [ngClass]="element.status | statusColor">
              <mat-chip>{{ element.status }}</mat-chip>
            </mat-chip-set>
          </td>
        </ng-container>

        <ng-container matColumnDef="lastUploaded">
          <th mat-header-cell *matHeaderCellDef>Last updated</th>
          <td mat-cell *matCellDef="let element">{{ element.lastUploaded }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="6">
            @if (isLoading()) {
              <div class="empty-table">
                <div class="empty-table-message-info">Loaging...</div>
              </div>
            } @else if (!dataSource.getValue().length) {
              <div class="empty-table">
                <div class="empty-table-message-info">{{ emptyListMessage() }}</div>
              </div>
            }
          </td>
        </tr>
      </table>
    </div>
    <button
      mat-button
      color="primary"
      type="button"
      class="action-button mt-2"
      (click)="seeMore()"
      data-test-id="seeMoreBtn"
    >
      See More
    </button>
  </mat-card-content>
</mat-card>
