import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { ChunkLoadErrorHandlerComponent } from './components/chunk-load-error-handler/chunk-load-error-handler.component';

const routes: Routes = [
  {
    path: '**',
    component: ChunkLoadErrorHandlerComponent,
  },
];

@NgModule({
  declarations: [ChunkLoadErrorHandlerComponent],
  imports: [CommonModule, RouterModule.forChild(routes), SharedModule],
  exports: [RouterModule],
})
export class ChunkLoadErrorHandlerModule {}
