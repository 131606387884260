import { Component, DestroyRef, input, OnInit, output, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { catchError, map, of, startWith, switchMap, tap } from 'rxjs';
import { OrganizationsService } from '../../services/organizations.service';

@Component({
  selector: 'app-menu-organizations',
  templateUrl: './menu-organizations.component.html',
  styleUrl: './menu-organizations.component.scss',
  standalone: false,
})
export class MenuOrganizationsComponent implements OnInit {
  organization = output<number>();
  selectedOrganization = input.required<number | null>();

  isLoading = signal<boolean>(false);
  readonly formFilter = new FormControl('');

  organizationList = toSignal(
    this.organizationsService.getList().pipe(
      map((data) => {
        this.isLoading.set(true);
        return {
          list: data,
          filtered: data,
        };
      }),
      tap(() => {
        this.isLoading.set(false);
      }),
      tap((data) => {
        if (data.list.length !== 0) {
          if (data.list.some((org) => org.pk === this.selectedOrganization())) {
            this.form.get('organization')?.setValue([Number(this.selectedOrganization())]);
          } else {
            this.form.get('organization')?.setValue([data.list[0].pk]);
          }
        }
      }),
      switchMap((data) => {
        return this.formFilter.valueChanges.pipe(
          startWith(''),
          map((filter: string | null) => {
            if (filter) {
              data.filtered = data.list.filter((el) =>
                el.name.toLocaleLowerCase().includes((filter || '').trim().toLowerCase()),
              );
            } else {
              data.filtered = [...data.list];
            }
            return data;
          }),
        );
      }),
      catchError(() => {
        return of(null);
      }),
    ),
  );

  readonly form = new FormGroup({
    organization: new FormControl<any>(null),
  });

  constructor(
    private readonly organizationsService: OrganizationsService,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.form
      .get('organization')
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: number[]) => {
        if (value?.length !== 0) {
          this.organization.emit(value[0]);
        }
      });
  }
}
