<h2 mat-dialog-title class="modal-dialog">
  Session timeout
  <button
    mat-icon-button
    type="button"
    class="dialog-close-button"
    (click)="onContinue()"
    aria-label="Close Dialog and Continue"
  >
    <mat-icon aria-hidden="true">clear</mat-icon>
  </button>
</h2>
<mat-dialog-content>
  <div>
    <p>
      You have been inactive for a while. For your security, you will be logged out in
      {{ timer() | date: 'mm:ss' }} minutes. If you would like to stay logged in, please click the button below.
    </p>
  </div>
  <mat-dialog-actions>
    <button mat-button color="primary" class="action-button" (click)="onLogout()" aria-label="Logout Application">
      Log out
    </button>
    <button
      mat-flat-button
      color="primary"
      class="action-button-primary"
      cdkFocusInitial
      (click)="onContinue()"
      aria-label="Continue"
    >
      Stay logged in
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
