import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'snack-bar-message-warning',
  templateUrl: './snack-bar-message-warning.component.html',
  styleUrl: './snack-bar-message-warning.component.scss',
  standalone: false,
})
export class SnackBarMessageWarningComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public readonly data: string,
    private readonly snackBarRef: MatSnackBarRef<string>,
  ) {}

  onClose() {
    this.snackBarRef.dismissWithAction();
  }
}
