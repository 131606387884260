<div class="d-flex">
  <div matSnackBarLabel>
    {{ data }}
  </div>
  <div matSnackBarActions>
    <button mat-icon-button matSnackBarAction type="button" aria-label="Close Message" (click)="onClose()">
      <mat-icon aria-hidden="true">clear</mat-icon>
    </button>
  </div>
</div>
