export enum DataSourceDataType {
  Text = 'Text',
  Integer = 'Integer',
  Decimal = 'Decimal',
  DateTime = 'DateTime',
  JSON = 'JSON',
}

export const DataSourceDataTypes = [
  DataSourceDataType.Text,
  DataSourceDataType.Integer,
  DataSourceDataType.Decimal,
  DataSourceDataType.DateTime,
  DataSourceDataType.JSON,
];

export const DataSourceDataTypeMap = new Map<string, DataSourceDataType>([
  ['text', DataSourceDataType.Text],
  ['integer', DataSourceDataType.Integer],
  ['decimal', DataSourceDataType.Decimal],
  ['datetime', DataSourceDataType.DateTime],
  ['json', DataSourceDataType.JSON],
]);

export const validDataTypes = [DataSourceDataType.Integer, DataSourceDataType.DateTime];
