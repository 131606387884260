<button
  *ngIf="closeWithoutConfirm; else closeWithConfirm"
  mat-icon-button
  type="button"
  mat-dialog-close
  aria-label="Close Dialog"
>
  <mat-icon aria-hidden="true">clear</mat-icon>
</button>

<ng-template #closeWithConfirm>
  <button mat-icon-button type="button" aria-label="Close Dialog" class="dialog-close-button">
    <mat-icon aria-hidden="true">clear</mat-icon>
  </button>
</ng-template>
