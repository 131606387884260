import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SecurityModule } from '@features/security';
import { DeviderComponent } from './components/devider/devider.component';
import { DialogCloseButtonComponent } from './components/dialog-close-button/dialog-close-button.component';
import { DialogConfirmCloseComponent } from './components/dialog-confirm-close/dialog-confirm-close.component';
import { ErrorPageContentComponent } from './components/error-page-content/error-page-content.component';
import { FieldErrorsMessagesComponent } from './components/field-errors-messages/field-errors-messages.component';
import { HeaderComponent } from './components/header/header.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { MenuComponent } from './components/menu/menu.component';
import { ToolsPanelComponent } from './components/tools-panel/tools-panel.component';
import { StepperDisableLastStep, StopPropagationActionDirective, StopPropagationScrollDirective } from './directives';
import { IsOverflowFieldDirective } from './directives/is-overflow-field/is-overflow-field.directive';
import { DateFormatPipe, ServerStringConverterPipe, StatusColorPipe, StringifyObjectPipe } from './pipes';

const matModules = [
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatTableModule,
  MatTabsModule,
  MatSortModule,
  MatToolbarModule,
  MatMenuModule,
  MatProgressBarModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatSidenavModule,
  MatListModule,
  MatSlideToggleModule,
  MatRadioModule,
  MatButtonToggleModule,
  MatExpansionModule,
  MatSnackBarModule,
];

const components: Type<any>[] = [
  DialogCloseButtonComponent,
  FieldErrorsMessagesComponent,
  DeviderComponent,
  InfoCardComponent,
  MenuComponent,
  HeaderComponent,
  ToolsPanelComponent,
  DialogConfirmCloseComponent,
  ErrorPageContentComponent,
];
const directives: Type<any>[] = [
  StopPropagationActionDirective,
  StepperDisableLastStep,
  StopPropagationScrollDirective,
  IsOverflowFieldDirective,
];
const pipes: Type<any>[] = [StatusColorPipe, DateFormatPipe, ServerStringConverterPipe, StringifyObjectPipe];

@NgModule({
  declarations: [...components, ...directives, ...pipes],
  imports: [CommonModule, OverlayModule, SecurityModule.forChild(), RouterModule, ...matModules],
  exports: [...matModules, OverlayModule, ...components, ...directives, ...pipes],
  providers: [DatePipe],
})
export class SharedModule {}
