import { Component, Input } from '@angular/core';

@Component({
  selector: 'dialog-close-button',
  templateUrl: './dialog-close-button.component.html',
  styleUrl: './dialog-close-button.component.scss',
  standalone: false,
})
export class DialogCloseButtonComponent {
  @Input() closeWithoutConfirm: boolean = true;
}
