export function sortObjectArrayByKey<T>(array: T[], key: keyof T, ascending: boolean = true): T[] {
  if (!array?.length) return array;

  const direction = ascending ? 1 : -1;

  return [...array].sort((a, b) => {
    const valueA = String(a[key]).toLowerCase();
    const valueB = String(b[key]).toLowerCase();

    if (valueA > valueB) return direction;
    if (valueA < valueB) return -direction;
    return 0;
  });
}
