import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, Router, RouterStateSnapshot } from '@angular/router';
import { LOCAL_STORAGE } from '@core/injectors';
import { of, switchMap } from 'rxjs';
import { AccountService } from '../services/account/account.service';

export const accountGuardMatch: CanMatchFn = () => {
  const account = inject(AccountService);
  return !!account.accessToken;
};

export const accountGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const account = inject(AccountService);
  const storage = inject(LOCAL_STORAGE);

  const initiaLink = router.getCurrentNavigation()?.initialUrl.toString() || '/';
  storage.setItem('redirect_url', initiaLink);

  return of(!!account.accessToken).pipe(
    switchMap((state: boolean) => {
      if (!state) {
        return of(router.createUrlTree(['/auth/login']));
      }
      return of(state);
    }),
  );
};

export const notAccountGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const account = inject(AccountService);
  const storage = inject(LOCAL_STORAGE);

  return of(!account.accessToken).pipe(
    switchMap((state: boolean) => {
      if (!state) {
        return of(router.createUrlTree(['/']));
      }
      return of(state);
    }),
  );
};
