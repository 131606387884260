import { Component, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserInfo } from '@core/models';
import { SelectedOrganizationService, UserService } from '@core/services';
import { AccountService } from '@features/server-side-authentication';
import { map, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  standalone: false,
})
export class HeaderComponent {
  public homeUrl = input<string[]>(['/']);

  public userInfo = toSignal<UserInfo>(
    this.userService.getUserData().pipe(
      map((el) => {
        return {
          name: el?.name || '<user name>',
          snowflake_url: el?.snowflake_url || '',
          zendesk_agent: el?.zendesk_agent || false,
          thoughspot_url: el?.thoughspot_url || '',
        };
      }),
    ),
  );

  public userOrganization = toSignal<string>(
    this.route.params.pipe(
      map<Params, string>((params) => params['organizationId']),
      switchMap((orgId) => {
        if (this.accountService.isInternal && orgId) {
          return this.selectedOrganizationService.getSelectedOrganizationName(+orgId).pipe(
            map((organizationName) => {
              this.userService.setSelectedOrganizationName(organizationName);
              return organizationName;
            }),
          );
        } else {
          return of(this.userService.userOrganization || '<no organization>');
        }
      }),
    ),
    {},
  );

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private accountService: AccountService,
    private selectedOrganizationService: SelectedOrganizationService,
  ) {}

  public logout(): void {
    this.router.navigate(['/', 'auth', 'logout']);
  }
}
