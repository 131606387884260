<div class="container">
  <error-page-content>
    <h1 class="error-code">Loading error</h1>
    <h2 class="error-main-text">Sorry, we couldn’t load that page.</h2>
    <p>We encountered an issue while loading a necessary resource for this page. Please try refreshing the page.</p>
    <div class="d-flex justify-content-center">
      <div>
        <button mat-flat-button color="primary" class="error-nav-button" (click)="onRefresh()">Refresh</button>
      </div>
    </div>
  </error-page-content>
</div>
