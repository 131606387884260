import { Component, input } from '@angular/core';

@Component({
  selector: 'app-statistics-tile-organizations',
  templateUrl: './statistics-tile-organizations.component.html',
  styleUrl: './statistics-tile-organizations.component.scss',
  standalone: false,
})
export class StatisticsTileOrganizationsComponent {
  statistic = input.required<any>();
}
