import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from '@environments/environment';
import { AccountService } from '../services/account/account.service';

export const jwtInterceptor: HttpInterceptorFn = (req, next) => {
  const account = inject(AccountService);
  const isLoggedIn = !!account.accessToken;

  if (isLoggedIn) {
    let authToken = `Bearer ${account.accessToken}`;

    if (environment.staticToken) {
      authToken = `Token ${environment.staticToken}`;
    }

    req = req.clone({
      setHeaders: { Authorization: authToken },
    });
  }

  return next(req);
};
