export const DataInstance = {
  create: (date: string | number | Date | null = null) => (date ? new Date(date) : new Date()),
  createDate: (date: string | number | Date | null = null): string => {
    const d = DataInstance.create(date);
    d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
    const dateStr = d.toJSON().split('T')[0];
    return dateStr;
  },

  /**
   * Create a date string in the format 'YYYY-MM-DD' given a day of the month.
   * If day is 'last', the date will be set to the last day of the month.
   * Otherwise, the date will be set to the given day of the month.
   * @param day - the day of the month to create the date for
   * @returns a string in the format 'YYYY-MM-DD'
   */
  createDateByDay: (day: string | number | null = null): string => {
    const d = DataInstance.create();
    if (day === 'last') {
      d.setDate(28);
    } else {
      d.setDate(Number(day));
    }

    d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
    const dateStr = d.toJSON().split('T')[0];

    return dateStr;
  },
};
