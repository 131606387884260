import { Directive, Input, ViewContainerRef } from '@angular/core';
import { SecurityService } from './security.service';

@Directive({
  selector: '[accessEnableSubmit]',
  standalone: false,
})
export class AccessEnableSubmitDirective {
  @Input('accessEnableSubmit') set permissions(value: string[]) {
    if (!this.security.hasAccess(value)) {
      (this.viewContainerRef as any)._lContainer[0][8].disabled = true;
    }
  }

  constructor(
    private readonly security: SecurityService,
    private viewContainerRef: ViewContainerRef,
  ) {}
}
