import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root',
})
export class AccessPageService {
  constructor(
    @Inject('defaultPath') protected readonly path: string,
    private readonly security: SecurityService,
    private readonly router: Router,
  ) {}

  canActivate(permissions: string[]): boolean {
    const access = this.security.hasAccess(permissions);
    if (!access) {
      if (this.path) {
        this.router.navigateByUrl(this.path, { skipLocationChange: true });
      }
    }
    return access;
  }
}
