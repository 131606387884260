import { Directive, Host } from '@angular/core';
import { MatStep, MatStepper } from '@angular/material/stepper';

@Directive({
  selector: '[stepperDisableLastStep]',
  standalone: false,
})
export class StepperDisableLastStep {
  constructor(@Host() private stepper: MatStepper) {
    this.overrideStepperNavigation();
  }

  private overrideStepperNavigation() {
    this.stepper['_anyControlsInvalidOrPending'] = (index: number) => {
      if (index !== 4) {
        return false;
      } else {
        return !this.stepper.steps
          .toArray()
          .slice(0, -1)
          .every((step) => step.completed);
      }
    };

    const originalStepIsNavigable = this.stepper._stepIsNavigable.bind(this.stepper);
    this.stepper._stepIsNavigable = (index: number, step: MatStep): boolean => {
      if (index !== 4) {
        return true;
      } else {
        return originalStepIsNavigable(index, step);
      }
    };
  }
}
