import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'error-page-content',
  templateUrl: './error-page-content.component.html',
  styleUrl: './error-page-content.component.scss',
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class ErrorPageContentComponent {}
