import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfirmCloseComponent } from '@shared/index';

@Injectable({
  providedIn: 'root',
})
export class CloseConfirmationService {
  private dialogRef!: MatDialogRef<any>;

  constructor(private readonly dialog: MatDialog) {}

  closeConfirm() {
    this.dialogRef = this.dialog.open<DialogConfirmCloseComponent, any, any>(DialogConfirmCloseComponent, {
      maxWidth: '560px',
    });
    return this.dialogRef.afterClosed();
  }

  closeDialog() {
    this.dialogRef?.close();
  }
}
