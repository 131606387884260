<h5 mat-dialog-title class="modal-dialog">
  Unsaved Changes
  <dialog-close-button></dialog-close-button>
</h5>

<div class="col d-flex flex-direction-column m-4">
  <h3>
    Closing this window without saving will result in losing all provided details. Are you sure you want to close it?
  </h3>
  <mat-dialog-actions>
    <button mat-button color="primary" type="button" class="action-button" mat-dialog-close>Cancel</button>
    <button
      mat-flat-button
      color="primary"
      type="submit"
      class="action-button-primary"
      cdkFocusInitial
      (click)="this.dialogRef.close(true)"
    >
      Discard Changes
    </button>
  </mat-dialog-actions>
</div>
