import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { AccessDataViewPipe } from './access-data-view.pipe';
import { AccessEnableSubmitDirective } from './access-enable-submit.directive';
import { AccessFormFieldDirective } from './access-form-field.directive';
import { AccessStylingDirective } from './access-styling.directive';
import { AccessViewDirective } from './access-view.directive';
import { SecurityService } from './security.service';

function ConfigPathFactory(path: string): string {
  return path;
}

@NgModule({
  declarations: [
    AccessViewDirective,
    AccessEnableSubmitDirective,
    AccessStylingDirective,
    AccessDataViewPipe,
    AccessFormFieldDirective,
  ],
  exports: [
    AccessViewDirective,
    AccessEnableSubmitDirective,
    AccessStylingDirective,
    AccessFormFieldDirective,
    AccessDataViewPipe,
  ],
  imports: [CommonModule],
})
export class SecurityModule {
  /**
   * @param path feature toggle tool path
   * @param defaultPath path to redirect
   */
  static forRoot(path: string | InjectionToken<string>, defaultPath: string): ModuleWithProviders<SecurityModule> {
    let provider: Provider;
    if (typeof path === 'string') {
      provider = { provide: 'basePath', useValue: path };
    } else {
      provider = { provide: 'basePath', useFactory: ConfigPathFactory, deps: [path] };
    }

    return {
      ngModule: SecurityModule,
      providers: [
        SecurityService,
        provider,
        {
          provide: 'defaultPath',
          useValue: defaultPath,
        },
      ],
    };
  }

  static forChild(): ModuleWithProviders<SecurityModule> {
    return {
      ngModule: SecurityModule,
    };
  }
}
