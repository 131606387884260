import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class DefaultOrganizationService {
  constructor(@Inject(DOCUMENT) private readonly doc: Document) {}

  saveOrgnaizationCookie(id: number) {
    const date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    this.doc.cookie = `defaultOrganizationId=${id};${expires};path=/`;
  }

  getOrganizationCookie() {
    const cookiesArray = this.doc.cookie.split(';');
    for (let cookie of cookiesArray) {
      if (cookie.startsWith('defaultOrganizationId=')) {
        return +cookie.substring(22, cookie.length);
      }
    }
    return null;
  }
}
