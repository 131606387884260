import { Component, Inject } from '@angular/core';
import { WINDOW } from '@core/injectors';

@Component({
  selector: 'chunk-load-error-handler',
  templateUrl: './chunk-load-error-handler.component.html',
  styleUrl: './chunk-load-error-handler.component.scss',
  standalone: false,
})
export class ChunkLoadErrorHandlerComponent {
  constructor(@Inject(WINDOW) private readonly win: Window) {}

  onRefresh() {
    this.win.location.reload();
  }
}
