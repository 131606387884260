import { Component, signal, ViewChild } from '@angular/core';
import { DefaultOrganizationService } from '@core/services';
import { PageOrganizationsComponent } from '../page-organizations/page-organizations.component';

@Component({
  selector: 'app-home-organization',
  templateUrl: './home-organization.component.html',
  styleUrl: './home-organization.component.scss',
  standalone: false,
})
export class HomeOrganizationComponent {
  organizationId = signal<number | null>(null);
  @ViewChild(PageOrganizationsComponent) pageOrganizationsComponent!: PageOrganizationsComponent;

  constructor(private readonly organizationService: DefaultOrganizationService) {
    this.organizationId.set(this.organizationService.getOrganizationCookie());
  }

  onChangeOrganization(id: any) {
    this.organizationId.set(id);
    this.organizationService.saveOrgnaizationCookie(id);
    this.pageOrganizationsComponent.onChangeOrganization(id);
  }
}
