import { Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params } from '@angular/router';
import { map } from 'rxjs';

@Component({
  selector: 'app-page-organization',
  templateUrl: './page-organization.component.html',
  styleUrl: './page-organization.component.scss',
  standalone: false,
})
export class PageOrganizationComponent {
  static isOrganizationRootPage = true;

  relatedRoute = toSignal<string[], string[]>(
    this.route.params.pipe(map<Params, string[]>((params) => ['organizations', params['organizationId']])),
    { initialValue: [] },
  );
  constructor(public readonly route: ActivatedRoute) {}
}
