import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SecurityService } from './security.service';

@Directive({
  selector: '[accessView]',
  standalone: false,
})
export class AccessViewDirective {
  @Input('accessView') set permissions(value: string[]) {
    if (this.security.hasAccess(value)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly security: SecurityService,
  ) {}
}
