import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MenuComponent {
  relatedRoute = input<string[]>([]);

  buildRoute(path: string[]) {
    return ['/', ...this.relatedRoute(), ...path];
  }
}
