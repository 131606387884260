import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '@core/injectors';
import { OrganizationItemDTO } from '@features/organizations/models';
import { map, Observable } from 'rxjs';

@Injectable()
export class SelectedOrganizationService {
  constructor(
    private readonly http: HttpClient,
    @Inject(API_BASE_URL) private readonly apyBaseUrl: string,
  ) {}

  getSelectedOrganizationName(id: number): Observable<string> {
    return this.http.get<OrganizationItemDTO[]>(`${this.apyBaseUrl}/api/organizations-internal/`).pipe(
      map((organizations) => {
        const selectedOrg: OrganizationItemDTO | undefined = organizations.find((org) => org.pk === id);
        return selectedOrg?.name || '';
      }),
    );
  }
}
