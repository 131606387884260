import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringifyObject',
  standalone: false,
})
export class StringifyObjectPipe implements PipeTransform {
  transform(value: any): string {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    }
    return value;
  }
}
